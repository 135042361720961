/* App.css */

.App {
  text-align: center;
  margin-bottom: 15px;
}

.App-header {
  position: fixed;
  top: 0px;
  padding: 10px;
  width: 80%;
  background-color: white;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  z-index: 1000;
  box-shadow: 0 4px 2px -2px gray;
  left: 50%;
  transform: translateX(-50%);
}

.App-logo {
  height: 100%;
  width: 100px;
  pointer-events: none;
  object-fit: cover;
  border-radius: 8px;
}
.contact-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.contact-button1{
  background-color: blue;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.contact-button:hover {
  background-color: darkblue;
}
.contact-button1:hover {
  background-color: darkblue;
}

h2 {
  margin-left: 20px;
}

.App-content {
  margin-top: 120px; /* Adjust to ensure content starts below the fixed header */
  padding: 8px;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  max-width: 100%;
  height: 500px;
}


.proofs-container {
  width: 70%;
  margin: auto;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-Top:20px;
}

.container-heading {
  background-color: #0047ab; 
  color: white;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  margin: 0;
}

.company-section {
  margin-top: 20px;
}

.company-name {
  color: #0047ab;
  text-align: left;
  margin-bottom: 10px;
}

.images-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.myproof-image{
    width: 280px;
    height:280px;
    object-fit: contain;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}
.proof-image {
  width:30%;
  height:15%;
  object-fit: cover;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.proof-image:hover {
  transform: scale(1.3); /* Slightly enlarges the image on hover */
}
.myproof-image:hover {
  transform: scale(1.5); /* Slightly enlarges the image on hover */
}
.course-content {
  width:70%;
  
  margin-top: 20px;
  margin: auto;
}


.course-points {
  list-style-type: disc;
  margin-left: 20px;
  color: #333;
  font-size: 16px;
  text-align: left;
}

.course-points li {
  margin-bottom: 10px;
}
